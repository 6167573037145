import { Dropdown, DropdownItemProps, Form } from "semantic-ui-react";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

const searchOptions: DropdownItemProps[] = [
    { text: "Insured", value: "insured" },
    { text: "Policy", value: "policy" }
];

type SearchType = "policy" | "insured";

const QuickSearch: React.FC = () => {
    const [searchType, setSearchType] = useState<SearchType>("policy");
    const [text, setText] = useState<string>("");
    const navigate = useNavigate();

    const handleSubmit = () => {
        const searchValue = encodeURIComponent(text.trim());
        setText("");
        if (searchType === "policy") {
            navigate(`/policy-search?q=${searchValue}`);
        } else if (searchType === "insured") {
            navigate(`/insured-search?q=${searchValue}`);
        }
    };

    return (
        <Form onSubmit={handleSubmit} size="tiny">
            <Form.Input
                action={
                    <Dropdown
                        compact
                        button
                        basic
                        floating
                        value={searchType}
                        onChange={(e, { value }) => setSearchType(value as SearchType)}
                        options={searchOptions}
                    />
                }
                size="small"
                actionPosition="left"
                placeholder="Search..."
                value={text}
                onChange={(e, { value }) => setText(value)}
            />
        </Form>
    );
};

export default QuickSearch;
