import envConfigDev from "./config.dev.json";
import envConfigProd from "./config.prod.json";
import envConfigUat from "./config.uat.json";
import rootConfig from "./config.json";

interface Config {
    auth0: {
        audience: string;
        clientId: string;
        domain: string;
    };
    authenticateApiUrl: string;
    databrokerApiUrl: string;
    fasanoRegistrationUrl: string;
    fastWebUrl: string;
    fileApiUrl: string;
    servicingReportApiUrl: string;
    twentyfirstLEWebUrl: string;
}

const env: "dev" | "uat" | "prod" = import.meta.env.VITE_APP_ENVIRONMENT || "dev";
const envMap = {
    dev: envConfigDev,
    uat: envConfigUat,
    prod: envConfigProd
};

const envConfig: Config = envMap[env] as Config;

const config: Config = {
    ...rootConfig,
    ...envConfig
};

export default config;
