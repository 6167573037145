import { useContext, useEffect, useState } from "react";

import { AppContext } from "../components/AppContextProvider";

/** A user is considered a "servicing user" if they have some portfolios. This hook will return `false` until the portfolios have been fetched for the user */
const useIsServicingUser = (): boolean => {
    const [isServicingUser, setIsServicingUser] = useState<boolean>(false);
    const { portfolios, medicalPortfolios } = useContext(AppContext);
    useEffect(() => {
        setIsServicingUser((!!portfolios && !!portfolios.length) || (!!medicalPortfolios && !!medicalPortfolios.length));
    }, [medicalPortfolios, portfolios]);
    return isServicingUser;
};

export default useIsServicingUser;
