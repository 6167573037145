import { Button, Checkbox, Modal } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

import { lexservTermsServiceURL } from "../services/Constants";
import { parseDates } from "../services/Library";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "../hooks/useSproc";

interface ClientTC {
    ClientID: number;
    NAME: string;
    IsCurrent: boolean;
    CurrentTCDate: string | Date;
}

const tcDateFields: (keyof ClientTC)[] = ["CurrentTCDate"];

const TermsConditions: React.FC = () => {
    const { logout } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [tcClients, setTCClients] = useState<ClientTC[]>([]);
    const [currentTCDate, setCurrentTCDate] = useState<string>();

    const sproc = useSproc();

    const setTCAgreement = async () => {
        try {
            await sproc("SetLexservTermsConditionsDate", {
                ClientsToUpdate: tcClients.map((client) => {
                    return { col1: client.ClientID.toString() };
                })
            });
            setIsModalOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await sproc("GetLexservTermsConditionsDate");
                const parsedData = parseDates(data, tcDateFields, "TRIM Z");
                const tcClientArr = parsedData.filter((client) => !client.IsCurrent) || [];
                setCurrentTCDate(parsedData[0].CurrentTCDate.toLocaleDateString());
                setIsModalOpen(tcClientArr.length > 0);
                setTCClients(tcClientArr);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [sproc]);

    return (
        <>
            <Modal
                open={isModalOpen}
                closeOnDimmerClick={false}
                onClose={() => {
                    setIsModalOpen(false);
                }}
            >
                <Modal.Header>Terms of Service</Modal.Header>
                <Modal.Content>
                    Please{" "}
                    <a style={{ textDecoration: "underline" }} href={lexservTermsServiceURL} rel="noopener noreferrer" target="_blank">
                        click here
                    </a>{" "}
                    to view the Terms of Service. By clicking “Accept,” you acknowledge that you have read and agree with the Terms of
                    Service on behalf of each client selected below.
                    <br />
                    <br />
                    {tcClients.map((client) => {
                        const { ClientID, NAME, IsCurrent } = client;
                        return (
                            <div key={ClientID}>
                                <Checkbox
                                    key={ClientID}
                                    label={NAME}
                                    checked={IsCurrent}
                                    onChange={() => {
                                        const updateTCClients = tcClients.map((client) => {
                                            if (client.ClientID === ClientID) {
                                                client.IsCurrent = !IsCurrent;
                                            }
                                            return client;
                                        });
                                        setTCClients(updateTCClients);
                                    }}
                                />
                                <br />
                            </div>
                        );
                    })}
                    <br />
                    Last update: {currentTCDate}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic negative icon="times" content="Decline" onClick={() => logout()} />
                    <Button
                        positive
                        icon="checkmark"
                        content="Accept"
                        disabled={tcClients.filter((client) => !client.IsCurrent).length > 0}
                        onClick={() => setTCAgreement()}
                    />
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default TermsConditions;
