import { setOptions } from "highcharts";

const chartDefaults = {
    colors: {
        brandInfo: "#199AD6",
        brandPrimary: "#023C5B"
    }
};

setOptions({
    chart: {
        style: {
            fontFamily: "Lato, 'Roboto', 'Arial', 'Helvetica Neue', sans-serif",
            color: chartDefaults.colors.brandPrimary
        }
    },
    lang: {
        thousandsSep: ","
    },
    plotOptions: {
        series: {
            // Don't do animations
            animation: false
        }
    },
    credits: {
        // Don't show "Highcharts" link on chart
        enabled: false
    },
    colors: [chartDefaults.colors.brandInfo, chartDefaults.colors.brandPrimary, "#8FBFE0", "#7C77B9", "#F79824"]
});
