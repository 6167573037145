import { Client, Portfolio, UserPermissions } from "../types";
import { createContext, useCallback, useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import useSproc from "../hooks/useSproc";

export interface AppContextProps {
    portfolios?: Portfolio[];
    selectedPortfolioIDs?: number[];
    setSelectedPortfolioIDs: (id: number[] | undefined) => void;
    medicalPortfolios?: Portfolio[];
    unreadMessageCount: number;
    getUnreadMessageCount: () => void;
    permissions: UserPermissions;
    userClients?: Client[];
    selectedClientIDs?: number[];
    setSelectedClientIDs: (id: number[] | undefined) => void;
}

export const AppContext = createContext<AppContextProps>({
    permissions: { Admin: false, MedrecClient: false, CanAccessFasano: false },
    unreadMessageCount: 0,
    getUnreadMessageCount: () => {
        throw new Error("not implemented");
    },
    setSelectedPortfolioIDs: () => {
        throw new Error("not implemented");
    },
    setSelectedClientIDs: () => {
        throw new Error("not implemented");
    }
});

interface AppContextProviderProps {
    children: React.ReactNode;
}
const AppContextProvider: React.FC<AppContextProviderProps> = ({ children }) => {
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [selectedPortfolioIDs, setSelectedPortfolioIDs] = useState<number[]>();
    const [userClients, setUserClients] = useState<Client[]>();
    const [selectedClientIDs, setSelectedClientIDs] = useState<number[]>();
    const [portfolios, setPortfolios] = useState<Portfolio[]>();
    const [medicalPortfolios, setMedicalPortfolios] = useState<Portfolio[]>();
    const [permissions, setPermissions] = useState<UserPermissions>({ Admin: false, MedrecClient: false, CanAccessFasano: false });

    const sproc = useSproc();
    const { isAuthenticated } = useAuth0();

    const getUnreadMessageCount = useCallback(async () => {
        try {
            if (isAuthenticated) {
                const [{ UnreadMessageCount }] = await sproc<{ UnreadMessageCount: number }>("GetClientUnreadMessageCount");
                setUnreadMessageCount(UnreadMessageCount);
            }
        } catch (err) {
            toast.error("Failed to get unread messages");
        }
    }, [isAuthenticated, sproc]);

    useEffect(() => {
        (async () => {
            await getUnreadMessageCount();
        })();
    }, [getUnreadMessageCount]);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        (async () => {
            const [userClientData, [permissionData]] = await Promise.all([
                sproc<Client>("GetUserClients"),
                sproc<UserPermissions>("GetLexservUserPermissions")
            ]);
            setUserClients(userClientData);
            const selectedClientArray = userClientData.map((client) => client.ClientID);
            setSelectedClientIDs(selectedClientArray);
            setPermissions(permissionData);
        })();
    }, [sproc, isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated) {
            return;
        }
        (async () => {
            const data = await sproc<Portfolio>("GetPortfolios");
            const servicingPortfolios = data.filter((port) => port.IsServicingPortfolio);
            const medicalPortfolios = data.filter((port) => !port.IsServicingPortfolio && port.MedRetrieval);
            setPortfolios(servicingPortfolios);
            setMedicalPortfolios(medicalPortfolios);
            if (data.length > 0) {
                const selectedPortfolioArray = [];
                for (let i = 0; i < data.length; i++) {
                    selectedPortfolioArray.push(data[i].PortfolioID);
                }
                setSelectedPortfolioIDs(selectedPortfolioArray);
            }
        })();
    }, [sproc, isAuthenticated]);

    useEffect(() => {
        if (portfolios && selectedPortfolioIDs && selectedPortfolioIDs.length === 0) {
            const selectedPortfolioArray = portfolios.map((portfolio) => portfolio.PortfolioID);
            setSelectedPortfolioIDs(selectedPortfolioArray);
        }
    }, [portfolios, selectedPortfolioIDs]);

    return (
        <AppContext.Provider
            value={{
                unreadMessageCount,
                getUnreadMessageCount,
                selectedPortfolioIDs,
                setSelectedPortfolioIDs,
                portfolios,
                medicalPortfolios,
                userClients,
                setSelectedClientIDs,
                selectedClientIDs,
                permissions
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
