/* eslint-disable @typescript-eslint/no-explicit-any */

import { isDate } from "lodash";

export const notImplemented = () => {
    throw new Error(`Not implemented!`);
};

export function formatDate(date: Date): string {
    if (!date) return "";
    return date.toLocaleDateString();
}

export const formatDropdownOptions = (input: any[], options: { key: string; text: string; value: string }) => {
    return input.map((x: any) => {
        return {
            key: x[options.key],
            value: x[options.value],
            text: x[options.text]
        };
    });
};

export function fixFilename(filename: string) {
    const newFilename: string = filename
        .replace(/[^A-Za-z0-9 -_.]/gi, " ") // whitelist chars
        .replace(/&amp;/gi, "") // kendo converts & to &amp; - remove
        .replace(/[|&;$%@"'#<>[\]{},!+]/g, " ") // remove these chars
        .replace(/ +/g, " ") // trim extra spaces
        .replace(/ .pdf$/, ".pdf"); // remove space before extension
    return newFilename;
}

export function decodeSpecialCharacters(input: string): string {
    const tempText = document.createElement("textarea");
    tempText.innerHTML = input;
    return tempText.value;
}

export function toIsoDateString(date: Date): string {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

/**
 * Returns an array of objects by parsing the selected fields as Date objects
 * @param {Object[]} data - An array of objects
 * @param {string[]} dateFields - An array of field names to be parsed as Date objects
 */
export const parseDates = <T>(data: T[], fields: (keyof T)[], behavior?: "TRIM Z"): T[] => {
    const parsed = data.map((row) => {
        const mutableRow = { ...row };
        fields.forEach((field) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const value = mutableRow[field] as any;
            if (value) {
                const preparedValue = behavior === "TRIM Z" ? value.slice(0, -1) : value;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                mutableRow[field] = new Date(preparedValue) as any;
            }
        });
        return mutableRow;
    });

    return parsed as T[];
};

export const formatCurrency = (value: number, decimals = 2): string => {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: decimals }).format(value);
};

export const replaceSpecialCharacters = (stringToCheck: string): string =>
    stringToCheck
        .replace(/&amp;apos;/gi, "'")
        .replace(/&lt;/gi, "<")
        .replace(/&gt;/gi, ">")
        .replace(/&quot;/gi, `"`)
        .replace(/&apos;/gi, `'`)
        .replace(/&amp;/gi, "&")
        .replace(/&rsquo;/gi, "'")
        .replace(/&ndash;/gi, "--");

export const isValidSSN = (ssn: string): boolean => {
    const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;
    return ssnRegex.test(ssn);
};

export function fromIsoDateString(date: string): Date {
    const [year, month, day] = date.split("-");
    return new Date(+year, +month - 1, +day);
}

export function displayDate(date?: Date | string | undefined | null) {
    if (isDate(date)) {
        return date.toLocaleDateString();
    } else if (typeof date === `string`) {
        return `INVALID DATE`;
    }
    return null;
}
