import "semantic-ui-css/semantic.min.css";
import "./styles/app.scss";
import "./styles/highcharts";
import "./styles/kendo.scss";
import "@itm21st/faceted-filter/dist/style.css";
import "react-toastify/dist/ReactToastify.css";

import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import AuthError from "./components/AuthError";
import CatchAllRedirect from "./components/CatchAllRedirect";
import ClientPolicyUploadQueue from "./features/ClientPolicyUpload/ClientPolicyUploadQueue";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import SimplePlaceholder from "./components/SimplePlaceholder";
import { useAuth0 } from "@auth0/auth0-react";

const AddInsured = lazy(() => import("./features/MedRecOrder/AddInsured"));
const CarrierRatings = lazy(() => import("./features/CarrierRatings/CarrierRatings"));
const ChangePassword = lazy(() => import("./features/PasswordManagement/ChangePassword"));
const Dashboard = lazy(() => import("./features/Dashboard/Dashboard"));
const DeathClaimsQueue = lazy(() => import("./features/DeathClaimsQueue/DeathClaimsQueue"));
const DocumentManagement = lazy(() => import("./features/DocumentManagement/DocumentManagement"));
const GraceQueue = lazy(() => import("./features/GraceQueue/GraceQueue"));
const Inbox = lazy(() => import("./features/Inbox/Inbox"));
const Insured = lazy(() => import("./features/Insured/InsuredContextProvider"));
const InsuredManagement = lazy(() => import("./features/MedRecOrder"));
const InsuredSearch = lazy(() => import("./features/InsuredSearch/InsuredSearch"));
const InsuredTracking = lazy(() => import("./features/InsuredTracking/InsuredTracking"));
const Insureds = lazy(() => import("./features/Insureds/Insureds"));
const LEDelta = lazy(() => import("./features/LEDelta/LEDelta"));
const MedicalRecords = lazy(() => import("./features/MedicalRecords/MedicalRecordsContextProvider"));
const MedicalRecordsInsureds = lazy(() => import("./features/MedicalRecords/MedicalRecordsInsureds"));
const MedRecInsuredSearch = lazy(() => import("./features/MedRecSearch/InsuredSearch"));
const MedRecSearch = lazy(() => import("./features/MedRecSearch/MedRecSearch"));
const MyAccount = lazy(() => import("./features/MyAccount/MyAccount"));
const PaymentHolds = lazy(() => import("./features/PaymentHolds/PaymentHolds"));
const Policies = lazy(() => import("./features/Policies/Policies"));
const Policy = lazy(() => import("./features/Policy/PolicyContextProvider"));
const PolicyLoans = lazy(() => import("./features/PolicyLoans/PolicyLoans"));
const PolicyOptQueue = lazy(() => import("./features/PolicyOptQueue/PolicyOptQueue"));
const PolicySearch = lazy(() => import("./features/PolicySearch/PolicySearch"));
const SecureFileTransfer = lazy(() => import("./features/SecureFileTransfer/SecureFileTransfer"));
const UserManagement = lazy(() => import("./features/MyAccount/UserManagement"));
const ReportGenerator = lazy(() => import("./features/ReportGenerator/ReportGenerator"));

const App: React.FC = () => {
    const { isLoading, error } = useAuth0();
    if (isLoading) {
        return <SimplePlaceholder />;
    }

    if (error) {
        return <AuthError error={error} />;
    }

    return (
        <Suspense fallback={<p>Loading...</p>}>
            <Routes>
                <Route path="/" element={<PrivateRoute component={Layout} />}>
                    <Route path="/add-insured/" element={<PrivateRoute component={AddInsured} AllowIfMedRecClient />} />
                    <Route path="/carrier-ratings" element={<CarrierRatings />} />
                    <Route path="/change-password" element={<ChangePassword />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/death-claims" element={<DeathClaimsQueue />} />
                    <Route path="/document-management" element={<DocumentManagement />} />
                    <Route path="/policy-upload" element={<ClientPolicyUploadQueue />} />
                    <Route path="/grace-queue" element={<GraceQueue />} />
                    <Route path="/inbox" element={<Inbox />} />
                    <Route path="/insured/:InsuredID" element={<Insured />} />
                    <Route
                        path="/insured-management/:InsuredID"
                        element={<PrivateRoute component={InsuredManagement} AllowIfMedRecClient />}
                    />
                    <Route path="/insured-search" element={<InsuredSearch />} />
                    <Route path="/insured-tracking" element={<InsuredTracking />} />
                    <Route path="/insureds" element={<Insureds />} />
                    <Route path="/le-delta" element={<LEDelta />} />
                    <Route path="/medical-records" element={<MedicalRecords />} />
                    <Route path="/medical-records-insureds" element={<MedicalRecordsInsureds />} />
                    <Route path="/medrec-insured-search" element={<MedRecInsuredSearch />} />
                    <Route path="/medrec-search" element={<MedRecSearch />} />
                    <Route path="/my-account" element={<MyAccount />} />
                    <Route path="/payment-holds" element={<PaymentHolds />} />
                    <Route path="/policies" element={<Policies />} />
                    <Route path="/policy/:PolicyID" element={<Policy />} />
                    <Route path="/policy-loans" element={<PolicyLoans />} />
                    <Route path="/policy-opt-queue" element={<PolicyOptQueue />} />
                    <Route path="/policy-search" element={<PolicySearch />} />
                    <Route path="/report-generator" element={<ReportGenerator />} />
                    <Route path="/secure-file-transfer" element={<SecureFileTransfer />} />
                    <Route path="/user-management" element={<PrivateRoute component={UserManagement} AllowIfAdmin />} />
                    <Route path="/" element={<CatchAllRedirect />} />
                    <Route path="*" element={<CatchAllRedirect />} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default App;
