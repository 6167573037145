import React from "react";

const KendoGridLoader: React.FC = () => {
    return (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
        </div>
    );
};

export default KendoGridLoader;
