// import { Header, Icon } from "semantic-ui-react";

import { Navigate } from "react-router-dom";
import React from "react";

// import { AppContext } from "./AppContextProvider";

// import useIsServicingUser from "../hooks/useIsServicingUser";

const CatchAllRedirect: React.FC = () => {
    // const { portfolios, medicalPortfolios } = useContext(AppContext);
    // const isServicingUser = useIsServicingUser();
    // if (!portfolios || !medicalPortfolios) {
    //     return <Header color="grey" content="Checking permissions..." icon={<Icon name="cog" loading />} />;
    // }
    return <Navigate to={"/inbox"} replace />;
};

export default CatchAllRedirect;
