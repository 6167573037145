import { Button, Grid, Icon, Message } from "semantic-ui-react";

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AuthError: React.FC<{ error: Error }> = ({ error }) => {
    const { loginWithRedirect, logout } = useAuth0();
    if (error.message === "user is blocked") {
        error.message = `Your account has been locked due to inactivity. For your security, we lock accounts that have not logged in after 90 days. To reactivate your account, follow the Forgot Password flow to set a new password.`;
    }

    return (
        <Grid centered padded="vertically">
            <Grid.Column width="6" textAlign="center">
                <Message icon error>
                    <Icon name="exclamation triangle" />
                    <Message.Content>
                        <Message.Header>Authentication Error</Message.Header>
                        <p>{error.message}</p>
                        <Button type="button" color="grey" content="Retry" onClick={loginWithRedirect} />
                        <br />
                        <br />
                        <Button type="button" color="grey" content="Return to Login" onClick={() => logout()} />
                    </Message.Content>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default AuthError;
