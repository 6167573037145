import { Link } from "react-router-dom";
import React from "react";

interface PolicyLinkProps {
    id: number;
    name: string | number;
    target?: string;
}

const PolicyLink: React.FC<PolicyLinkProps> = ({ id, name, target }) => {
    return (
        <Link to={`/policy/${id}`} target={target}>
            {name}
        </Link>
    );
};

export default PolicyLink;
