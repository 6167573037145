import { Button, Checkbox, Grid, Input } from "semantic-ui-react";
import { ClientPolicyUpload, ClientPolicyUploadRow } from "../../types";
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, filterBy, orderBy } from "@progress/kendo-data-query";
import { FacetedFilter, Filter, FilterEvent, FilterOptions } from "@itm21st/faceted-filter";
import { GridColumn as KendoColumn, Grid as KendoGrid, GridToolbar as KendoToolbar } from "@progress/kendo-react-grid";
import React, { useCallback, useEffect, useState } from "react";

import ClientPolicyUploadModal from "./ClientPolicyUploadModal";
import KendoGridLoader from "../../components/KendoGridLoader";
import PageHeader from "../../components/PageHeader";
import PolicyLink from "../../components/PolicyLink";
import { kendoDateFormat } from "../../services/Constants";
import { parseDates } from "../../services/Library";
import { toast } from "react-toastify";
import useDatabroker from "../../hooks/useDatabroker";

const searchableFields: (keyof ClientPolicyUpload)[] = [
    "PolicyNumber",
    "Insured1FirstName",
    "Insured1LastName",
    "Insured2FirstName",
    "Insured2LastName"
];

const dateFields: (keyof ClientPolicyUploadRow)[] = ["PolicyCreatedDate", "CreatedDate"];

const filterOptions: FilterOptions[] = [
    {
        property: "PortfolioName",
        label: "Portfolio",
        defaultCollapsed: false
    },
    {
        property: "CompanyName",
        label: "Carrier",
        defaultCollapsed: false
    },
    {
        property: "Status",
        label: "Status",
        defaultCollapsed: false
    }
];

const ClientPolicyUploadQueue: React.FC = () => {
    const [gridData, setGridData] = useState<ClientPolicyUploadRow[]>();
    const [sort, setSort] = useState<SortDescriptor[]>([{ field: "CreatedDate", dir: "asc" }]);
    const [textSearch, setTextSearch] = useState<string>("");
    const [filter, setFilter] = useState<CompositeFilterDescriptor>({ logic: "or", filters: [] });
    const [facetedFilter, setFacetedFilter] = useState<Filter>();
    const [loading, setLoading] = useState<boolean>(false);
    const [includeCompleted, setIncludeCompleted] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const filteredData = filterBy(gridData || [], filter);
    const orderedData = orderBy(filteredData, sort);
    const databroker = useDatabroker();

    useEffect(() => {
        const newFilter: CompositeFilterDescriptor = { logic: "and", filters: [] };
        if (textSearch) {
            const textFilters: FilterDescriptor[] = searchableFields.map((key) => ({
                field: key,
                operator: "contains",
                ignoreCase: true,
                value: textSearch.trim()
            }));
            newFilter.filters.push({ logic: "or", filters: textFilters });
        }
        if (facetedFilter) newFilter.filters.push(facetedFilter);
        setFilter(newFilter);
    }, [textSearch, facetedFilter]);

    const getClientPolicyUploadQueue = useCallback(async () => {
        try {
            setLoading(true);
            const { data, error } = await databroker.sproc<ClientPolicyUploadRow>({
                objectName: "GetClientPolicyUploadQueue",
                parameters: { IncludeCompleted: includeCompleted }
            });
            if (error || !data) {
                toast.error("Error fetching data");
                throw new Error(error);
            }
            const tempData = parseDates(data, dateFields, "TRIM Z");
            setGridData(tempData);
        } catch (err) {
            if (err instanceof Error) {
                console.error(err.message);
            }
        } finally {
            setLoading(false);
        }
    }, [databroker, includeCompleted]);

    useEffect(() => {
        (async () => await getClientPolicyUploadQueue())();
    }, [getClientPolicyUploadQueue]);

    return (
        <>
            <PageHeader
                icon="upload"
                rightContent={<Button primary content={"Upload Policy"} icon="upload" onClick={() => setIsModalOpen(true)} />}
                title="Policy Upload"
            />
            <Grid>
                <Grid.Column width="4">
                    {!!gridData && gridData.length > 0 && (
                        <FacetedFilter
                            data={gridData}
                            filterOptions={filterOptions}
                            onFilterChange={(event: FilterEvent) => setFacetedFilter(event.filter)}
                        />
                    )}
                </Grid.Column>
                <Grid.Column width="12">
                    {loading && <KendoGridLoader />}
                    <KendoGrid
                        className="k-grid-less-padding"
                        data={orderedData}
                        onSortChange={(e) => setSort(e.sort)}
                        resizable
                        sort={sort}
                        sortable
                        style={{ height: "calc(100vh - 175px)" }}
                        total={filteredData.length}
                    >
                        <KendoToolbar>
                            <Grid columns="equal">
                                <Grid.Column floated="left">
                                    <Input
                                        icon="search"
                                        onChange={(_e, data) => setTextSearch(data.value)}
                                        placeholder="Search..."
                                        type="search"
                                        value={textSearch}
                                    />
                                </Grid.Column>
                                <Grid.Column floated="right" textAlign="right">
                                    <Checkbox
                                        checked={includeCompleted}
                                        label="Include Completed"
                                        onChange={(e, { checked }) => setIncludeCompleted(!!checked)}
                                    />
                                </Grid.Column>
                            </Grid>
                        </KendoToolbar>
                        <KendoColumn field="CreatedDate" title="Date" format={kendoDateFormat} />
                        <KendoColumn field="PolicyNumber" title="Policy Number" />
                        <KendoColumn field="PortfolioName" title="Portfolio" />
                        <KendoColumn field="CompanyName" title="Carrier" />
                        <KendoColumn field="Status" title="Status" />
                        <KendoColumn
                            title="Insured(s)"
                            cell={({ dataItem }) => {
                                const { Insured1FirstName, Insured1LastName, Insured2FirstName, Insured2LastName } = dataItem;
                                const firstName = Insured1LastName + ", " + Insured1FirstName;
                                let lastName = "";
                                if (Insured2FirstName && Insured2LastName) {
                                    lastName = Insured2LastName + ", " + Insured2FirstName;
                                }

                                return (
                                    <td>
                                        {firstName}
                                        {lastName && (
                                            <>
                                                <br />
                                                {lastName}
                                            </>
                                        )}
                                    </td>
                                );
                            }}
                        />
                        <KendoColumn field="Notes" title="Notes" />
                        {includeCompleted && (
                            <KendoColumn
                                field="PolicyID"
                                title="PolicyID"
                                cell={({ dataItem }) => {
                                    const { PolicyID } = dataItem as ClientPolicyUpload;
                                    if (!PolicyID) return <td></td>;
                                    return (
                                        <td>
                                            <PolicyLink id={PolicyID} name={PolicyID} />
                                        </td>
                                    );
                                }}
                            />
                        )}
                        {includeCompleted && <KendoColumn field="PolicyCreatedDate" title="Policy Created Date" format={kendoDateFormat} />}
                    </KendoGrid>
                </Grid.Column>
            </Grid>
            {isModalOpen && (
                <ClientPolicyUploadModal getClientPolicyUploadQueue={getClientPolicyUploadQueue} setIsModalOpen={setIsModalOpen} />
            )}
        </>
    );
};

export default ClientPolicyUploadQueue;
